import React from "react";
import Header from "./Header";
import Products from "./Products";
import { useEffect } from "react";

const Index = () => {
  useEffect(()=>{
    window.scroll(0, 0)
  },[])
  
  return (
    <>
      <Header />
      <Products />
    </>
  );
};

export default Index;
