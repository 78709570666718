import React from "react";
import Form from "./Form";
import Header from "./Header";
import { useEffect } from "react";

const Index = () => {
  useEffect(()=>{
    window.scroll(0, 0)
  },[])
  
  return (
      <section>
        <Header />
        <Form />
      </section>
  );
};

export default Index;
