import React from "react";
import Header from "./Header";
import Partners from "./Partners";
import Team from "./Team";
import Values from "./Values";
import { useEffect } from "react";

const Index = () => {
  useEffect(()=>{
    window.scroll(0, 0)
  },[])

  return (
    <>
      <Header />
      <main>
        <Values />
        <Team />
        <Partners />
      </main>
    </>
  );
};

export default Index;
