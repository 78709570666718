import React, { useEffect } from "react";
import Header from "./Header";
import NewPosts from "./AllPosts";
import Pagination from "../../components/Paginate";
import { useGlobalContext } from "../../context";

const Index = () => {
  const { blogs, loading } = useGlobalContext()
  useEffect(()=>{
    window.scroll(0, 0)
  },[])

  return (
    <>
      <Header posts={blogs} loading={loading} />
      <main>
        <NewPosts posts={blogs} loading={loading} />
        <Pagination />
      </main>
    </>
  );
};

export default Index;
